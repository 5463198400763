import {
  ActionArea,
  Button,
  Card,
  Grid,
  Heading,
  Modal,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { HelpIcon } from '@enterprise-ui/icons'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

const ORIGIN_VIEW = 'ORIGIN'
const SCAC_VIEW = 'SCAC'

const DescriptionModal = () => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <Button onClick={() => setIsVisible(true)}>
        <EnterpriseIcon icon={HelpIcon} size="sm" style={{ marginRight: 5 }} />
        About
      </Button>

      <Modal
        headingText="About the Middle Mile General Merchandise Dashboard"
        isVisible={isVisible}
        onRefuse={() => setIsVisible(false)}
        size="normal"
      >
        <div className="hc-pa-normal">
          <Grid.Container direction="column">
            <Grid.Item>
              <div>
                The purpose of this dashboard is to give visibility to at risk,
                early, late and missed loads and ensure critical updates are
                provided by carriers to Target. Loads will fall in to the
                following categories if they meet the below criteria. To view
                the specific load details and provide updates to those loads,
                click on any of the numeric values and the loads will
                pre-populate in DLM load tracking.
              </div>

              <div className="hc-pt-md">
                Default view time period: Expected Delivery Date Range (Arrival
                Time) = 8AM day prior - 7:59am current day
              </div>
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">Total Loads</div>
              </strong>
              Total loads with an Expected delivery date rage of 8AM day prior –
              7:59 current day
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">On-Time Loads</div>
              </strong>
              Loads with no alert status
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">Defect Loads</div>
              </strong>
              Total of At Risk, Early, Late and Missed loads
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">
                  Defect Loads Missing Reason Codes
                </div>
              </strong>
              Total of At Risk, Early, Late and Missed loads with no delivery
              defect reason code entered in DLM Tracking
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">At Risk</div>
              </strong>
              arrival time (expected delivery) has passed, and no EDI 214 X1
              (arrived at delivery) timestamp has been received
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">Early</div>
              </strong>
              EDI 214 X1 (arrived at delivery) event timestamp is 1 or more
              hours prior to arrival time for live unloads only
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">Late</div>
              </strong>
              EDI 214 X1 (arrived at delivery) event timestamp is after arrival
              date/time (expected delivery)
            </Grid.Item>

            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">Missed</div>
              </strong>
              EDI 214 X1 (arrived at delivery) timestamp is 3 or more hours past
              arrival time (expected delivery)
            </Grid.Item>

            <Grid.Item>
              <Grid.Container justify="flex-end">
                <Grid.Item>
                  <Button onClick={() => setIsVisible(false)}>Close</Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </>
  )
}

const ActionCard = ({
  className,
  filters,
  title,
  count,
  useStatusColor = true,
}) => {
  const statusClass = useStatusColor
    ? count === 0
      ? 'hc-clr-success'
      : 'hc-clr-error'
    : ''

  return (
    <Grid.Item className={className} xs={12} md={6} xl={3}>
      <ActionArea
        as={NavLink}
        to=".."
        state={{
          filters: filters,
        }}
        data-testid={`${title}-action-area`}
      >
        <Card className="dashboard-card">
          <Grid.Container direction="column" className="hc-pl-md hc-pt-md">
            <Grid.Item className="hc-pv-none">
              <div className="hc-fs-xs">{title}</div>
            </Grid.Item>
            <Grid.Item className="hc-pt-none">
              <div className={`${statusClass} hc-fs-3x`}>
                <strong>{count || 0}</strong>
              </div>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </ActionArea>
    </Grid.Item>
  )
}

const MMDashboard = ({
  loadSummary,
  subheader,
  formatStateFilters = (_filters) => {
    return {}
  },
}) => {
  const [onView, setOnView] = useState(ORIGIN_VIEW)
  const toggleOnView = () => {
    if (onView === SCAC_VIEW) {
      setOnView(ORIGIN_VIEW)
    } else if (onView === ORIGIN_VIEW) {
      setOnView(SCAC_VIEW)
    }
    mapLoadSummary(loadSummary)
  }

  const getTotalFiltersForCell = (summaries, displaySequence) => {
    const summariesForCell = summaries.flatMap((summary) =>
      summary.summary_cells?.filter(
        (cell) => cell.display_sequence === displaySequence,
      ),
    )
    const allFilters = summariesForCell.flatMap((summary) => summary.filters)
    const filterNames = [
      ...new Set(allFilters.map((filter) => filter?.filter_name)),
    ]
    return filterNames.map((filterName) => {
      return {
        filter_name: filterName,
        filter_values: [
          ...new Set(
            allFilters
              .filter((filter) => filter.filter_name === filterName)
              .flatMap((filter) => filter.filter_values),
          ),
        ],
      }
    })
  }

  const getTotalCountForCell = (summaries, displaySequence) => {
    const summariesForCell = summaries.flatMap((summary) =>
      summary.summary_cells?.filter(
        (cell) => cell.display_sequence === displaySequence,
      ),
    )
    return summariesForCell.reduce((acc, summary) => {
      acc += summary.load_count
      return acc
    }, 0)
  }

  const buildCells = (summary) => {
    const firstSummary = summary?.summaries[0]
    return Array.from({ length: 8 }, (_, i) => {
      const displaySequence = i + 1
      return {
        name: firstSummary?.summary_cells?.find(
          (cell) => cell.display_sequence === displaySequence,
        )?.display_name,
        total_count: getTotalCountForCell(summary?.summaries, displaySequence),
        total_filters: getTotalFiltersForCell(
          summary?.summaries,
          displaySequence,
        ),
      }
    })
  }

  let mappedLoadSummary
  const mapLoadSummary = (loadSummary) => {
    const dashboardSummaries = loadSummary?.dashboard_summaries
    const uniqueValues = (key) =>
      [
        ...new Set(dashboardSummaries?.map((details) => details[key])?.sort()),
      ].filter((value) => value !== 'All')

    const scacs = uniqueValues('scac')
    const origins = uniqueValues('origin')

    const mapSummaries = (key, value) =>
      dashboardSummaries
        .filter((summary) => summary[key] === value)
        .flatMap((summary) => ({
          name: summary[key === 'scac' ? 'origin' : 'scac'],
          summary_cells: summary.summary_cells,
        }))

    mappedLoadSummary = (onView === SCAC_VIEW ? scacs : origins).map(
      (value) => ({
        name: value,
        summaries: mapSummaries(
          onView === SCAC_VIEW ? 'scac' : 'origin',
          value,
        ),
      }),
    )

    return mappedLoadSummary
  }

  let overview
  const getAllSummary = (loadSummary) => {
    const allSummary = loadSummary?.dashboard_summaries?.find(
      (summary) => summary.scac === 'All',
    )
    overview = Array.from({ length: 8 }, (_, i) => {
      const displaySequence = i + 1
      return allSummary?.summary_cells?.find(
        (cell) => cell.display_sequence === displaySequence,
      )
    })
    return overview
  }

  const itemClassMap = {
    0: 'first-item',
    1: 'second-item',
    2: 'third-item',
    3: 'fourth-item',
    4: 'fifth-item',
    5: 'sixth-item',
    6: 'seventh-item',
    7: 'eighth-item',
  }

  return (
    <Card className="hc-pa-expanded">
      <Grid.Container direction="column">
        <Grid.Item>
          <Grid.Container justify="space-between">
            <Grid.Item>
              <Heading size={3}>
                Middle Mile General Merchandise Dashboard
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <DescriptionModal />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        <Grid.Item>{subheader}</Grid.Item>
        <Grid.Item>
          <Grid.Container>
            <Grid.Item>
              <Heading size={4}>
                <strong>Overview</strong>
              </Heading>
            </Grid.Item>
            <Grid.Item className="hc-mt-min">
              All selected Origins and SCACs.
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        {getAllSummary(loadSummary) && (
          <Grid.Item className="mm-overview">
            <Grid.Container direction="row" align="center">
              {overview.map((cell, index) => (
                <ActionCard
                  key={index}
                  className={itemClassMap[index]}
                  title={cell?.display_name}
                  count={cell?.load_count}
                  filters={formatStateFilters(cell?.filters)}
                  useStatusColor={index > 1}
                />
              ))}
            </Grid.Container>
          </Grid.Item>
        )}
        {mapLoadSummary(loadSummary) && (
          <>
            <Grid.Item>
              <Grid.Container justify="flex-end">
                <Grid.Item>
                  <Button
                    onClick={() => toggleOnView()}
                    aria-label="Switch Table View Button"
                  >
                    Switch to {onView === SCAC_VIEW ? 'Origin' : 'SCAC'} View
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item>
              {mappedLoadSummary.map((summary) => {
                const cells = buildCells(summary)
                return (
                  <Card
                    className="hc-pa-expanded hc-mb-expanded"
                    key={summary?.name}
                  >
                    <Heading size={4}>{summary?.name}</Heading>
                    <Table name={`${summary?.name} Details`}>
                      <Table.Head>
                        <Table.Row>
                          <Table.Header xs={1}>
                            {onView === 'SCAC' ? 'Origin ID' : 'SCAC'}
                          </Table.Header>
                          {cells.map((cell, index) => (
                            <Table.Header xs={1} key={index}>
                              {cell?.name}
                            </Table.Header>
                          ))}
                        </Table.Row>
                      </Table.Head>
                      <Table.Body>
                        <Table.Row>
                          <Table.Data xs={1}>
                            <NavLink
                              to=".."
                              state={{
                                filters: formatStateFilters(
                                  cells[7]?.total_filters,
                                ),
                              }}
                            >
                              <strong>Total</strong>
                            </NavLink>
                          </Table.Data>
                          {cells.map((cell, index) => (
                            <Table.Data xs={1} key={index}>
                              <NavLink
                                to=".."
                                state={{
                                  filters: formatStateFilters(
                                    cell?.total_filters,
                                  ),
                                }}
                              >
                                <strong>
                                  <div
                                    className={`${
                                      index > 1 &&
                                      (cell?.total_count === 0
                                        ? 'hc-clr-success'
                                        : 'hc-clr-error')
                                    }`}
                                  >
                                    {cell?.total_count}
                                  </div>
                                </strong>
                              </NavLink>
                            </Table.Data>
                          ))}
                        </Table.Row>
                        {summary?.summaries?.map((detailsSummary) => {
                          const cells = Array.from({ length: 8 }, (_, i) =>
                            detailsSummary?.summary_cells?.find(
                              (cell) => cell.display_sequence === i + 1,
                            ),
                          )
                          return (
                            <Table.Row key={detailsSummary?.name}>
                              <Table.Data xs={1}>
                                <NavLink
                                  to=".."
                                  state={{
                                    filters: formatStateFilters(
                                      cells[7]?.filters,
                                    ),
                                  }}
                                >
                                  <strong>{detailsSummary?.name}</strong>
                                </NavLink>
                              </Table.Data>
                              {cells.map((cell, index) => (
                                <Table.Data xs={1} key={index}>
                                  <NavLink
                                    to=".."
                                    state={{
                                      filters: formatStateFilters(
                                        cell?.filters,
                                      ),
                                    }}
                                  >
                                    <div
                                      className={`${
                                        index > 1 &&
                                        (cell?.load_count === 0
                                          ? 'hc-clr-success'
                                          : 'hc-clr-error')
                                      }`}
                                    >
                                      {cell?.load_count}
                                    </div>
                                  </NavLink>
                                </Table.Data>
                              ))}
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table>
                  </Card>
                )
              })}
            </Grid.Item>
          </>
        )}
      </Grid.Container>
    </Card>
  )
}

export default MMDashboard
